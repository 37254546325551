import Image from 'next/image';

import ButtonBox from '@/components/ButtonBox';
import OnDesktop from '@/components/MediaQueries/OnDesktop';
import OnMobile from '@/components/MediaQueries/OnMobile';
import OnTablet from '@/components/MediaQueries/OnTablet';
import { MarkdownContent } from '@/components/v4/MarkdownContent';
import { JustAcquireProps as JustAcquireCMSProps } from '@/types/cms/home';

interface JustAcquireProps extends Omit<JustAcquireCMSProps, 'referId'> {
  id: string;
}

const JustAcquire: React.FC<JustAcquireProps> = ({
  id,
  tag,
  title,
  description,
  image,
  buttons,
}) => {
  return (
    <section
      id={id}
      className="flex relative justify-center mt-40 min-h-[668px] md:min-h-[588px] lg:min-h-[458px] xl:min-h-[558px] bg-display-100"
    >
      <div className="flex absolute top-[-48px] md:top-[-40px] lg:top-[-128px] xl:top-[-80px] flex-col lg:flex-row-reverse justify-center items-center px-24 m-auto lg:w-full max-w-7xl">
        <OnTablet>
          <Image
            src={image.tablet.path}
            alt={image.tablet.alternativeText}
            width={600}
            height={350}
            quality={70}
            style={{ objectFit: 'contain' }}
          />
        </OnTablet>

        <OnDesktop>
          <Image
            src={image.web.path}
            alt={image.web.alternativeText}
            width={791}
            height={558}
            quality={80}
            style={{ objectFit: 'contain' }}
            className="w-[600px] xl:w-[791px]"
          />
        </OnDesktop>

        <OnMobile>
          <Image
            src={image.mobile.path}
            alt={image.mobile.alternativeText}
            width={320}
            height={398}
            quality={70}
            style={{ objectFit: 'contain' }}
          />
        </OnMobile>

        <div className="flex flex-col justify-center items-start pt-32 md:pt-24 lg:pt-[160px] lg:mr-16 md:min-w-[404px] lg:min-w-[304px] max-w-[312px] md:max-w-[472px] lg:max-w-[404px] lg:min-h-[558px]">
          <p className="mt-8 w-full font-display text-sm lg:text-base font-semibold text-center lg:text-left text-display-600">
            {tag}
          </p>
          <MarkdownContent content={title} />
          <MarkdownContent
            content={description}
            className="mt-8 mb-16 w-full md:text-base lg:text-lg text-center lg:text-left text-display-900"
          />
          <ButtonBox
            buttons={buttons}
            section={title}
            className="m-auto lg:m-0 w-full md:w-auto font-semibold text-display-0 bg-stone-500 btn btn-primary btn-large"
          />
        </div>
      </div>
    </section>
  );
};

export default JustAcquire;
