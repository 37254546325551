'use client';

import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Icon from '@/components/v3/Icon';

import {
  chatIsOpenState,
  widgetIsHiddenState,
  widgetIsOpenState,
} from '../../atoms';
import { isWhatsappButton } from '../../utils/personalizations';
import styles from './styles.module.css';

const WhatsappButtonTestAB = () => {
  const { pathname } = useRouter();
  const chatOrWhatsappVariant = isWhatsappButton(pathname);
  const [widgetIsHidden, setWidgetIsHidden] =
    useRecoilState(widgetIsHiddenState);
  const setWidgetIsOpen = useSetRecoilState(widgetIsOpenState);
  const chatIsOpen = useRecoilValue(chatIsOpenState);

  const [htmlInject, setHtmlInject] = useState(``);
  const [isVisible, setIsVisible] = useState(false);

  const numberPhone = '552140421163';
  const whatsappMessage =
    'Oi!%20Eu%20gostaria%20de%20adquirir%20uma%20maquininha.%20Voc%C3%AA%20pode%20me%20ajudar?';

  const shouldDisplayWhatsappButton =
    chatOrWhatsappVariant === 1 && !chatIsOpen;

  const handleScroll = () => {
    const shouldShowButton = window.scrollY > 100;
    setIsVisible(shouldShowButton);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setWidgetIsOpen(false);

    if (shouldDisplayWhatsappButton) {
      !widgetIsHidden && setWidgetIsHidden(true);
      setHtmlInject(
        `#widget-open-floating-action-button{display:none}#active-chat-machine{bottom:136px;right:24px}`,
      );
    } else {
      setHtmlInject(
        `#widget-open-floating-action-button{display:flex}#active-chat-machine{bottom:94px}`,
      );
    }
  }, [
    chatOrWhatsappVariant,
    setWidgetIsHidden,
    setWidgetIsOpen,
    shouldDisplayWhatsappButton,
    widgetIsHidden,
  ]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: htmlInject,
        }}
      />

      {shouldDisplayWhatsappButton && (
        <>
          <Link
            href={`https://api.whatsapp.com/send?phone=${numberPhone}&text=${whatsappMessage}`}
            id="button-whatsapp-float"
            data-testid="link-whatsapp"
            target="_blank"
            className={`${
              isVisible ? 'flex' : 'hidden md:flex'
            } fixed right-16 md:right-24 lg:right-40 bottom-24 lg:bottom-56 z-[999] !w-auto lg:h-64 btn btn-large btn-primary font-sharon font-medium paragraph-16 bg-[#25D366] ${
              styles.animateShake
            }`}
          >
            <Icon name="whatsapp" className="text-display-900 fill-current" />
            <span className="ml-8 text-display-900">Adquira agora</span>
          </Link>
        </>
      )}
    </>
  );
};

export default WhatsappButtonTestAB;
