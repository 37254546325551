import classNames from 'classnames';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const OnMobile: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames('flex md:hidden', className)}>{children}</div>
  );
};

export default OnMobile;
