import Image from 'next/image';

export type TFlag = {
  name: string;
  description: string;
};

type TFlags = {
  Flags: TFlag[];
  title: string;
  textBg?: 0 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
};

const Flag: React.FC<TFlags> = ({ Flags, title, textBg = 0 }) => {
  return (
    <div className="mx-auto mb-16 !w-auto">
      <div className="flex flex-col items-center mt-[8px] whitespace-nowrap rounded-[1px] border border-b-0 border-display-300">
        <p
          className={`paragraph-14 mt-[-10px] px-4 font-sharon ${`bg-display-${textBg}`}`}
        >
          {title}
        </p>
      </div>
      <div className="flex flex-wrap gap-8 justify-center pt-4">
        {Flags.map(({ name, description }) => (
          <div
            key={description}
            className="relative !w-[45px] lg:!w-[56px] !h-[30px] lg:!h-[38px]"
          >
            <div className="relative w-full h-full">
              <Image
                src={`v1/site-stone/flags/${name}`}
                alt={description}
                layout="fill"
                quality={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Flag;
