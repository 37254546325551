'use client';

import React, { useCallback, useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import { MarkdownContent } from '@/components/v4/MarkdownContent';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { useQRCodeDrawer } from '@/contexts/qrcodeDrawer';
import { BeStoneProps as BeStoneCMSProps } from '@/types/cms/home';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';
import { useResponsiveQuery } from 'atomic-layout';

interface BeStoneProps extends Omit<BeStoneCMSProps, 'referId'> {
  id: string;
}
const BeStone: React.FC<BeStoneProps> = ({ id, title, buttons }) => {
  const { analytics } = useContext(AmplitudeContext);
  const { toggleDrawer, setQrCodeLink } = useQRCodeDrawer();
  const [utmUrl, setUtmUrl] = useState(LINKS.APP_LINK_APPSFLYER_QR_CODE_HOME);
  const isMobile = useResponsiveQuery({ to: 'md' });

  function trackCTA(reference: string) {
    analytics?.track({
      event_type: 'cta stone',
      event_properties: {
        name: 'cta stone',
        description:
          'Evento disparado no clique do cta da section São muitos motivos pra voce contar com a gente ',
        section_reference: 'são muitos motivos pra voce contar com a gente',
        cta_reference: reference,
      },
    });
  }

  const updateUtmUrl = useCallback(
    () =>
      setUtmUrl(handleAppsflyerUtmUrl(LINKS.APP_LINK_APPSFLYER_QR_CODE_HOME)),
    [],
  );

  useEffect(updateUtmUrl);

  return (
    <div className="py-40 md:py-80 px-24 w-full bg-display-100" id={id}>
      <div className="flex flex-col justify-center mx-auto w-full max-w-[455px] md:max-w-none lg:max-w-[840px]">
        <MarkdownContent
          content={title}
          className="font-display text-4xl lg:text-6xl font-bold leading-8 lg:leading-9 text-center"
        />
        <div className="flex flex-col md:flex-row gap-16 justify-center mt-24">
          {buttons.map(({ color, label, link }) =>
            link?.id ? (
              <a
                key={label}
                href={link.href}
                target={link.target}
                className="w-full md:w-auto md:max-w-max font-medium text-display-0 bg-stone-500 btn-primary btn-large btn"
                aria-label="Descubra suas taxas"
                onClick={() => trackCTA('Descubra suas taxas')}
              >
                {label}
              </a>
            ) : (
              <Button
                href={isMobile ? utmUrl : undefined}
                color={color}
                aria-label="Abra sua conta"
                className="flex md:hidden w-full md:max-w-max"
                onClick={() => {
                  trackCTA('Abra sua conta');
                  toggleDrawer();
                  setQrCodeLink(LINKS.APP_LINK_APPSFLYER_QR_CODE_HOME);
                }}
              >
                {label}
              </Button>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default BeStone;
