'use client';

import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import React, { useState } from 'react';

import ArrowLeft from '@/assets/icons/general/arrow-left-green.svg';
import ArrowRight from '@/assets/icons/general/arrow-right-green.svg';

import { IPlan } from '@/containers/Plans/components/Plans/planType';
import classNames from 'classnames';
import { Slide } from './Slide';

interface Carousel {
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banners: any[];
}

interface BannersCarouselProps {
  referId: string;
  banners_carousel: Carousel;
  plans: IPlan[];
}

export function BannersCarousel({
  referId,
  banners_carousel: { banners },
  plans,
}: BannersCarouselProps) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slides: { perView: 1 },

    slideChanged(s) {
      setCurrentSlide(Math.round(s.track.details.rel));
    },
  });

  return (
    <div className="overflow-hidden max-w-[100vw]" id={referId}>
      <div ref={sliderRef} className="flex relative h-max keen-slider">
        <div className="flex absolute inset-x-0 bottom-[40px] z-30 gap-16 justify-center items-center">
          <button
            type="button"
            className="cursor-pointer"
            title="botão anterior"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              slider.current?.prev();
            }}
            aria-label="Seta para esquerda"
          >
            <div
              className={classNames(
                `h-36 w-36 fill-current text-stone-600 bg-white opacity-80 rounded-full lg:hover:opacity-95 flex items-center justify-center`,
                {
                  'text-display-500 bg-opacity-80':
                    currentSlide === banners.length - 1,
                },
              )}
            >
              <ArrowLeft />
            </div>
          </button>
          <div className="flex gap-8 justify-center items-center">
            {banners.map((_, index) => {
              if (index === currentSlide) {
                return (
                  <div
                    key={index}
                    className="w-32 h-8 bg-stone-500 rounded-[8px]"
                  ></div>
                );
              }

              return (
                <div
                  key={index}
                  className="w-8 h-8 bg-display-50 rounded-[8px]"
                ></div>
              );
            })}
          </div>

          <button
            type="button"
            className="cursor-pointer"
            title="botão avançar"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              slider.current?.next();
            }}
          >
            <div
              aria-label="Seta para direita"
              className={classNames(
                `h-36 w-36 fill-current text-stone-600 bg-white opacity-80 rounded-full lg:hover:opacity-95 flex items-center  justify-center`,
                {
                  'text-display-500 bg-opacity-80':
                    currentSlide === banners.length - 1,
                },
              )}
            >
              <ArrowRight />
            </div>
          </button>
        </div>

        {banners?.map(
          (props, index) =>
            props && (
              <div className="min-w-[100vw] keen-slider__slide" key={index}>
                <Slide
                  key={`${index}.${props.name}`}
                  {...props}
                  plans={plans}
                  className="pb-[9px] md:pb-0 h-full"
                  inCarousel={true}
                />
              </div>
            ),
        )}
      </div>
    </div>
  );
}
