'use client';

import useAmplitudeTestAB from '@/hooks/useAmplitudeTestAB';

export interface CMSExperiment {
  amplitude_experiment: CMSAmplitudeExperiment;
  variantName: string;
  isControl: boolean;
}

export interface CMSAmplitudeExperiment {
  key: string;
  isActive: boolean;
}

interface Props {
  children: React.ReactNode;
  experiment?: CMSExperiment;
}
export function Experiment({ children, experiment }: Props) {
  const { activeVariant } = useAmplitudeTestAB(
    experiment?.amplitude_experiment?.key,
    experiment?.amplitude_experiment?.isActive,
  );

  const thereIsExperimentAtCMSToThisComponent = Boolean(
    experiment?.amplitude_experiment?.key,
  );
  const isThisControlComponent = Boolean(experiment?.isControl);
  const variantHaveNotBeenLoadedYet =
    thereIsExperimentAtCMSToThisComponent && !activeVariant;
  const isSameCMSAndAmplitudeVariant =
    experiment?.variantName === activeVariant;

  const shouldRenderThisComponent =
    isSameCMSAndAmplitudeVariant ||
    (variantHaveNotBeenLoadedYet && isThisControlComponent);

  if (thereIsExperimentAtCMSToThisComponent && !shouldRenderThisComponent) {
    return null;
  }

  return children;
}
