import ScrollingBarWithLink from '@/components/ScrollingBar/ScrollingBarWithLink';
import LINKS from '@/constants/links';
import Image from 'next/image';

const ScrollingBarBlackFriday = () => {
  return (
    <ScrollingBarWithLink
      className="p-12 bg-[#00231B]"
      pathname={LINKS.INBOUND}
      ctaReference="Faixa Rotativa de Black Friday enviando para página de Adquira padrão"
      eventDescription="Evento disparado quando o usuário clicar na faixa rotativa da Black Friday"
    >
      <section className="flex gap-16 justify-center items-center">
        <div className="flex justify-center items-center">
          <h6 className="font-display text-lg font-medium leading-[26px] text-white">
            Débito a partir de 0,79% l Crédito a partir de 2,89%
          </h6>
        </div>
        <div>
          <Image
            src="v1729002632/percent.png"
            height={34}
            width={40}
            alt="Icone de percentual"
          />
        </div>
        <div className="flex items-center py-4 px-16 mr-16 bg-[#c8fa3c] rounded-sm">
          <p className="font-display text-lg font-bold leading-3 text-[#00231b]">
            Black Friday
          </p>
        </div>
      </section>
    </ScrollingBarWithLink>
  );
};
export default ScrollingBarBlackFriday;
