import { IconsNames } from '../v3/Icon';

interface RootSprite<T> {
  name: T;
  className?: string;
}

interface BaseSpriteFn<T> extends RootSprite<T> {
  spriteName: string;
}

const baseSpriteFn = <T,>({ name, className = '' }: BaseSpriteFn<T>) => {
  return (
    <svg className={`${className}`}>
      <use xlinkHref={`/sprites.svg#${name}`}></use>
    </svg>
  );
};

export const IconSprite = (props: RootSprite<IconsNames>) =>
  baseSpriteFn({
    spriteName: 'sprite',
    className: `${props.className}`,
    ...props,
  });
