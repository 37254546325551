'use client';

import Modal from '@/components/v3/Modal';

interface DetailsModalProps {
  showModal: boolean;
  closeModal: () => void;
}

export function DetailsModal({ showModal, closeModal }: DetailsModalProps) {
  return (
    <Modal
      open={showModal}
      onCloseClick={() => closeModal()}
      data-testid="hero-modal"
      className="gap-24 items-center sm:items-start !p-24 lg:!p-40 max-w-[512px] h-auto"
    >
      <div className="flex flex-col gap-24 max-w-[624px]">
        <div className="flex gap-40 justify-between items-start">
          <h5 className="font-display font-bold">
            Confira os detalhes da oferta
          </h5>
          <button
            type="button"
            onClick={() => closeModal()}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className='after:inline-block after:scale-[1.5] cursor-pointer after:content-["\00d7"]'
          ></button>
        </div>
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-8">
            <p className="font-sharon">
              Oferta válida para clientes com
              <strong> faturamento acima de R$30 mil/mês </strong>na Stone.
            </p>

            <p className="font-sharon">
              Taxas promocionais aplicáveis{' '}
              <strong>somente a novos clientes,</strong> variando conforme o
              volume de vendas, com validade de 2 meses após a contratação da
              maquininha Stone.
            </p>

            <p>
              Clientes que ativarem o{' '}
              <strong>recebimento das suas vendas para o mesmo dia</strong>{' '}
              terão <strong> três meses de teste grátis,</strong> sendo
              reajustado ao valor normal
              <strong> após 90 dias.</strong>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
