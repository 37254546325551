import { InViewEvents, UnknownProps } from './InViewEvents';
import { CMSExperiment, Experiment } from './Experiment';
import { CMSEventParams } from '@/app/hooks/useAnalytics';

export type SectionProps = {
  id: string;
  name: string;
  trackView?: CMSEventParams[];
  experiment?: CMSExperiment;
};

export interface SectionRulesProps {
  component: React.FC<SectionProps>;
  props: SectionProps;
}

export function Fallback({ name }: { name: string }) {
  console.error(`${name} component not found`);
  return <p className="text-red-500">{name} component not found</p>;
}

function SectionComponent({ props, component }: SectionRulesProps) {
  const Component = component || Fallback;

  if (props?.trackView) {
    return (
      <InViewEvents
        events={props.trackView}
        componentProps={props as unknown as UnknownProps}
      >
        <Component {...props} />
      </InViewEvents>
    );
  }

  return <Component {...props} />;
}

export function SectionRules({ component, props }: SectionRulesProps) {
  const hasExperiment =
    props?.experiment &&
    props?.experiment?.amplitude_experiment?.key &&
    props?.experiment?.amplitude_experiment?.isActive;

  if (hasExperiment) {
    return (
      <Experiment experiment={props.experiment}>
        <SectionComponent component={component} props={props} />
      </Experiment>
    );
  }

  return <SectionComponent component={component} props={props} />;
}
