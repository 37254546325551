import { GetStaticProps } from 'next';
import {
  CorporateContactJsonLd,
  FAQPageJsonLd,
  NextSeo,
  SocialProfileJsonLd,
} from 'next-seo';
import dynamic from 'next/dynamic';

import EnterpriseAccount from '@/containers/EnterpriseAccount';
import { stoneCMSGetBy } from '@/services/stoneCMSApi';
import {
  BannerProps,
  FaqProps,
  FeaturesProps,
  TestimonialsProps,
  VideoStepsProps,
} from '@/types/cms';
import {
  BannerCardProps,
  BlogPostsProps,
  ComparativeListProps,
  RtbFeaturesProps,
  SectionGirarProps,
  ServiceVideoPjProps,
  SolutionsFeaturesProps,
} from '@/types/cms/conta-pj';
import { setItemsFaqSeo } from '@/utils/setItemsFaqSeo';

const EnterpriseAccountScrollingBar = dynamic(
  () => import('@/containers/Home/components/EnterpriseAccountScrollingBar'),
);

export interface PjAccountProps {
  hero: BannerProps;
  serviceVideoPj: ServiceVideoPjProps;
  features: FeaturesProps;
  bannerCard: BannerCardProps;
  videoSteps: VideoStepsProps;
  sectionGirar: SectionGirarProps;
  comparativeList: ComparativeListProps;
  rtbFeatures: RtbFeaturesProps;
  testimonials: TestimonialsProps;
  solutionsFeatures: SolutionsFeaturesProps;
  blogPosts: BlogPostsProps;
  faq: FaqProps;
}

export const getStaticProps: GetStaticProps<PjAccountProps> = async () => {
  const props = await stoneCMSGetBy('conta-pj');

  return {
    props,
  };
};

export const showContaPJ = process.env.ROTATIVE_BAR_CONTA_PJ === 'true';

const PjAccount: React.FC<PjAccountProps> = props => {
  return (
    <>
      <NextSeo
        title="Conta PJ com atendimento humano 24hrs - Stone"
        description="A Conta Pj Stone tem tudo pro seu negócio no mesmo app. Ferramentas de venda e gestão, crédito pro seu caixa e atendimento humano 24hrs disponível"
        canonical="https://www.stone.com.br/conta-pj/"
        openGraph={{
          type: 'website',
          url: 'https://www.stone.com.br/conta-pj/',
          title: 'Conta PJ com atendimento humano 24hrs - Stone',
          description:
            'A Conta Pj Stone tem tudo pro seu negócio no mesmo app. Ferramentas de venda e gestão, crédito pro seu caixa e atendimento humano 24hrs disponível',
          images: [
            {
              url: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1666733342/site-stone/structured-data/account-pj/account-pj-img-400-246.png',
              width: 400,
              height: 426,
              alt: 'Conta PJ com atendimento humano 24hrs - Stone',
              type: 'image/png',
            },
            {
              url: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1666733342/site-stone/structured-data/account-pj/account-pj-img-400-300.png',
              width: 400,
              height: 300,
              alt: 'Conta PJ com atendimento humano 24hrs - Stone',
              type: 'image/png',
            },
            {
              url: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1666733342/site-stone/structured-data/account-pj/account-pj-img-400-400.png',
              width: 400,
              height: 400,
              alt: 'Conta PJ com atendimento humano 24hrs - Stone',
              type: 'image/png',
            },
          ],
          site_name: 'Stone',
          locale: 'pt_BR',
        }}
      />

      {props.faq && (
        <FAQPageJsonLd mainEntity={setItemsFaqSeo(props.faq.questions)} />
      )}
      <CorporateContactJsonLd
        url="http://www.stone.com.br/"
        logo="https://www.stone.com.br/public/images/favicon.ico"
        contactPoint={[
          {
            telephone: '3004-9680',
            contactType: 'customer service',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '3004-9681',
            contactType: 'sales',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '0800 326-0506',
            contactType: 'customer service',
            areaServed: 'BR',
            contactOption: 'TollFree',
            availableLanguage: ['Brazilian Portuguese'],
          },
        ]}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Stone Pagamentos"
        url="http://www.stone.com.br/"
        sameAs={[
          'https://www.facebook.com/stonepagamentos/',
          'https://twitter.com/sejastone',
          'https://www.instagram.com/stone/',
          'https://www.youtube.com/channel/UCk4L4JykgoDkN8YeuxkNqIQ',
          'https://www.linkedin.com/company/stone-pagamentos',
          'https://pt.wikipedia.org/wiki/Stone_Pagamentos',
        ]}
      />
      {showContaPJ && (
        <EnterpriseAccountScrollingBar hash="condicoes-conta-pj-stone" />
      )}
      <EnterpriseAccount {...props} />
    </>
  );
};

export default PjAccount;
