'use client';

import classNames from 'classnames';
import Image from 'next/image';
import React, { useContext, useEffect } from 'react';

import { PartnershipsProps as PartnershipsCMSProps } from '@/types/cms/home';

import style from './Partnerships.module.css';
import { AmplitudeContext } from '@/contexts/amplitude';
import { useInView } from 'react-intersection-observer';

export interface PartnershipsProps
  extends Omit<PartnershipsCMSProps, 'referId'> {
  id: string;
  bg?: 0 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
}

const Partnerships: React.FC<PartnershipsProps> = ({
  id,
  partners,
  bg = 0,
}) => {
  const { analytics } = useContext(AmplitudeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina ',
          section_reference:
            'Parceira de mais de 1 milhão de negócios por todo o Brasil',
        },
      });
    }
  }, [analytics, inView]);
  return (
    <div
      id={id}
      className={`flex justify-center px-24 w-full ${`bg-display-${bg}`}`}
      ref={ref}
    >
      <div className="flex flex-col gap-24 justify-center items-center pt-40 pb-40 lg:pb-80 w-full max-w-7xl">
        <h2 className="font-display text-3xl lg:text-6xl font-bold leading-6 lg:leading-9 text-center">
          Parceira de mais de{' '}
          <span className="text-stone-500">1 milhão de negócios</span> por todo
          Brasil
        </h2>
        <div
          className={classNames(
            'hidden lg:flex flex-wrap sm:gap-x-[110px] md:gap-x-0 justify-between md:py-32 w-full h-[200px] md:h-auto',
            style.Container,
          )}
        >
          {partners.map(({ web: { path, alternativeText, width, height } }) => {
            return (
              <div className="flex items-center" key={path}>
                <div className="block">
                  <Image
                    src={path}
                    width={width}
                    height={height}
                    alt={alternativeText}
                    quality={75}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={classNames(
            'lg:hidden flex flex-wrap sm:gap-x-[110px] md:gap-x-0 justify-between md:py-32 w-full h-[200px] md:h-auto',
            style.Container,
          )}
        >
          {partners.map(
            ({ mobile: { path, alternativeText, width, height } }) => {
              return (
                <div className={`flex items-center ${style.item} `} key={path}>
                  <div className="block">
                    <Image
                      src={path}
                      width={width}
                      height={height}
                      alt={alternativeText}
                      quality={75}
                    />
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default Partnerships;
