import { IconsNames } from '@/components/v3/Icon';

import { TFlag } from '../components/Flag';

type TFlagsItems = {
  others: TFlag[];
  vouchers: TFlag[];
};

type TAddOnMachine = {
  name: string;
  iconName: IconsNames;
};

export const FlagsItems: TFlagsItems = {
  others: [
    {
      name: 'master',
      description: 'Bandeira Master',
    },
    {
      name: 'visa',
      description: 'Bandeira Visa',
    },
    {
      name: 'elo',
      description: 'Bandeira Elo',
    },
    {
      name: 'amex',
      description: 'Bandeira Amex',
    },
    {
      name: 'hipercard',
      description: 'Bandeira Hipercard',
    },
    {
      name: 'hiper',
      description: 'Bandeira Hiper',
    },
    {
      name: 'cabal',
      description: 'Bandeira Cabal',
    },
    {
      name: 'unionpay',
      description: 'Bandeira UnionPay',
    },
    {
      name: 'banes',
      description: 'Bandeira Banescard',
    },
    {
      name: 'up',
      description: 'Bandeira Up',
    },
    {
      name: 'verde',
      description: 'Bandeira Verdecard',
    },
    {
      name: 'avancard',
      description: 'Bandeira Avancard',
    },
    {
      name: 'bnb',
      description: 'Bandeira Bnb',
    },
    {
      name: 'good',
      description: 'Bandeira Good',
    },
    {
      name: 'personal',
      description: 'Bandeira Personal',
    },
    {
      name: 'senff',
      description: 'Bandeira Senff',
    },
    {
      name: 'trio',
      description: 'Bandeira Trio',
    },
    {
      name: 'eucard',
      description: 'Bandeira EuCard',
    },
    {
      name: 'megavale',
      description: 'Bandeira MegavaleCard',
    },
    {
      name: 'lecard',
      description: 'Bandeira Lecard',
    },
    {
      name: 'maxcard',
      description: 'Bandeira MaxCard',
    },
    {
      name: 'cdc',
      description: 'Bandeira CDC Card',
    },
    {
      name: 'bscash',
      description: 'Bandeira BsCash',
    },
    {
      name: 'bpf',
      description: 'Bandeira Banco Ponto Forte',
    },
    {
      name: 'applePay',
      description: 'Bandeira Apple Pay',
    },
    {
      name: 'googlePay',
      description: 'Bandeira Pay',
    },
    {
      name: 'samsungPay',
      description: 'Bandeira Samsung Pay',
    },
    {
      name: 'pix',
      description: 'Bandeira Pix',
    },
  ],
  vouchers: [
    {
      name: 'pluxee',
      description: 'Bandeira Pluxee',
    },
    {
      name: 'ticket',
      description: 'Bandeira Ticket',
    },
    {
      name: 'vr',
      description: 'Bandeira Vr',
    },
    {
      name: 'alelo',
      description: 'Bandeira Alelo',
    },
    {
      name: 'ben',
      description: 'Bandeira Ben',
    },
    {
      name: 'biq',
      description: 'Bandeira Biq',
    },
    {
      name: 'cooper',
      description: 'Bandeira Cooper',
    },
    {
      name: 'green',
      description: 'Bandeira Greencard',
    },
    {
      name: 'vale',
      description: 'Bandeira ValeCard',
    },
    {
      name: 'verdcard',
      description: 'Bandeira VerdCard',
    },
    {
      name: 'abrapetite',
      description: 'Bandeira Abrapetite',
    },
    {
      name: 'viasoft',
      description: 'Bandeira Viasoft',
    },
    {
      name: 'uauh',
      description: 'Bandeira Uauh beneficios',
    },
    {
      name: 'vegas',
      description: 'Bandeira Vegas',
    },
    {
      name: 'volus',
      description: 'Bandeira Volus',
    },
  ],
};

export const addOnMachine: TAddOnMachine[] = [
  {
    name: 'App pra gestão',
    iconName: 'phone-mobile',
  },
  {
    name: 'Troca em 1 dia útil',
    iconName: 'tool',
  },
  {
    name: 'Bateria que dura',
    iconName: 'battery-charging',
  },
  {
    name: 'Já vem com chip',
    iconName: 'sim-card',
  },
  {
    name: 'Bobina grátis',
    iconName: 'pos-coil',
  },
  {
    name: 'Wi-Fi',
    iconName: 'signal-wifi-2',
  },
  {
    name: '2G, 3G e 4G',
    iconName: 'chart-bar-3',
  },
];
