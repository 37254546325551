'use client';

import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { useAnalytics } from '@/app/hooks/useAnalytics';
import { ImageResponsiveCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';
import { DetailsModal } from './DetailsModal';

interface Fee {
  from: string;
  to: string;
  product: string;
}

export interface BlackFridayHeroProps {
  referId: string;
  title: string;
  description: string;
  images: ImageResponsiveCMSProps;
  buttons: ButtonProps[];
  className?: string;
  fees: Fee[];
}

export function BlackFridayHero({
  className,
  images,
  title,
  description,
  buttons,
  fees,
}: BlackFridayHeroProps) {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const { sendEvents } = useAnalytics();

  return (
    <>
      <div
        className={classNames(
          'flex justify-center items-center bg-display-0',
          className,
        )}
        id="Context_heroHomePage"
      >
        <div className="flex relative flex-col md:flex-row justify-end md:justify-center items-center w-full h-full">
          <div className="hidden lg:flex w-full h-full">
            <Image
              src={images?.web?.path}
              alt={images?.web?.alternativeText}
              layout="fill"
              quality={images?.webQuality || 70}
              className="object-cover"
            />
          </div>
          <div className="hidden md:flex lg:hidden w-full">
            <Image
              src={images?.tablet?.path}
              alt={images?.tablet?.alternativeText}
              width={1920}
              height={616}
              quality={images?.tabletQuality || 70}
              className="object-cover"
            />
          </div>
          <div
            className={classNames(
              'flex md:hidden relative -mt-24 order-1 w-full h-full',
            )}
          >
            <Image
              src={images?.mobile?.path}
              alt={images?.mobile?.alternativeText}
              width={1057}
              height={285}
              quality={images?.mobileQuality || 70}
              className="object-cover"
            />
          </div>
          <div className="flex md:absolute left-0 z-10 lg:justify-end lg:items-center p-24 lg:p-40 md:py-40 w-full md:w-1/2 lg:w-auto xl:w-1/2 bg-[#00231B] md:rounded-none md:rounded-r-base lg:rounded-r-6xl rounded-b-2xl">
            <div className="flex flex-col gap-24 lg:items-end w-full">
              <div className="flex flex-col gap-16 justify-center lg:justify-start items-center lg:items-start w-full lg:max-w-[601px]">
                <div className="flex flex-col gap-4 lg:gap-16">
                  <h2
                    className="md:block h-full font-display text-5xl lg:text-[64px] font-black leading-[44px] lg:leading-[56px] text-center lg:text-left text-stone-300"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />

                  <h5
                    className="max-w-[526px] font-display text-xl lg:text-2xl leading-5 lg:leading-6 text-center lg:text-left text-display-0"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
                <div className="flex flex-col lg:w-full xl:w-auto">
                  <div className="flex flex-wrap gap-8 lg:gap-y-16 lg:w-[471px] lg:max-w-[471px]">
                    <p className="w-full font-sharon text-base md:text-lg md:leading-[26px] text-center lg:text-left text-display-0">
                      Com taxas a partir de
                    </p>
                    <div className="grid grid-cols-2 gap-16 w-full lg:w-auto">
                      {fees.map(fee => (
                        <div
                          key={fee.product}
                          className="flex flex-col gap-4 justify-center items-center p-16 lg:w-[170px] rounded-base lg:rounded-md border-2 border-stone-600"
                        >
                          <h6 className="font-display text-5xl md:text-5xl lg:text-7xl font-black md:leading-[44px] lg:leading-10 text-stone-300">
                            {fee.to}
                          </h6>
                          <p className="font-sharon text-base font-semibold md:font-bold leading-4 text-display-0">
                            {fee.product}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <p className="mt-8 lg:mt-24 text-base text-center lg:text-left text-display-0">
                    Confira{' '}
                    <span
                      onClick={() => {
                        setShowModal(true);
                        sendEvents(
                          [
                            {
                              name: 'cta stone',
                              description:
                                'Evento disparado quando o usuário clicar nos ctas da LP',
                            },
                          ],
                          {
                            section: title,
                            cta: 'Confira mais detalhes',
                          },
                        );
                      }}
                      className="decoration-slice text-base text-stone-300 underline cursor-pointer"
                    >
                      mais detalhes da oferta
                    </span>
                  </p>
                  <div className="flex lg:grid flex-col lg:flex-row grid-cols-2 gap-16 lg:gap-24 mt-16 lg:mt-24 w-full">
                    {buttons?.map((button, index) => (
                      <Button
                        key={index}
                        {...button}
                        sectionReference={title}
                        className="w-full md:w-auto"
                        aria-label={button.label}
                      />
                    ))}
                    <Link
                      href="tel:3004 9681"
                      aria-label="Atendimento em 5 segundos no número 3004 9681"
                      onClick={() => {
                        sendEvents(
                          [
                            {
                              name: 'cta stone',
                              description:
                                'Evento disparado quando o usuário clicar nos ctas da LP',
                            },
                          ],
                          {
                            section: title,
                            cta: '3004 9681',
                          },
                        );
                      }}
                      className="flex flex-col flex-wrap flex-1 md:gap-0 items-center lg:items-start md:pt-0 max-w-5xl cursor-pointer"
                    >
                      <p className="max-w-5xl text-center md:text-left text-stone-300 paragraph-14">
                        Atendimento em 5 segundos
                      </p>
                      <span
                        className={`text-2xl text-center md:text-left font-bold whitespace-nowrap paragraph-24 text-display-0`}
                      >
                        3004 9681
                      </span>
                    </Link>
                  </div>
                  <DetailsModal showModal={showModal} closeModal={closeModal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
