import styles from './styles.module.css';
import { IconSprite } from '@/components/IconSprite';
import { IconsNames } from '@/components/v3/Icon';
import ScrollingBarWithLink from '@/components/ScrollingBar/ScrollingBarWithLink';

type MasterchefScrollingBarProps = {
  hash?: string;
};

const MasterchefScrollingBar: React.FC<MasterchefScrollingBarProps> = ({
  hash,
}) => {
  return (
    <ScrollingBarWithLink
      className="p-12 bg-black"
      pathname="/masterchefbr"
      hash={hash ?? ''}
      ctaReference="Faixa Rotativa na Home"
      eventDescription="Evento disparado quando o usuário clicar no CTAs do fluxo"
    >
      <div className="flex gap-8 justify-center items-center">
        <h6 className="font-display text-lg font-medium text-white">
          Tudo que o seu restaurante precisa pra vender, gerir e girar
        </h6>
      </div>
      <div className={`${styles.tickerBadge} px-16 mx-40`}>
        <p className="font-display text-base font-medium text-white">
          <span className="text-stone-300">Stone</span> no
        </p>
        <IconSprite
          name={'masterchef' as IconsNames}
          className="ml-4 w-[120px] h-[25px]"
        />
      </div>
    </ScrollingBarWithLink>
  );
};
export default MasterchefScrollingBar;
