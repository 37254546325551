import { IconsNames } from '@/components/v3/Icon';

export type TResourceFeatures = {
  title: string;
  subtitle: string;
  icon: IconsNames;
  span?: string;
};

export const resource: TResourceFeatures[] = [
  {
    icon: 'tablet',
    title: 'Venda online',
    subtitle: 'No Boleto ou no Link de pagamento em até 18x',
  },
  {
    icon: 'stone-card',
    title: 'Cartão Stone',
    subtitle: 'Cartão empresa grátis pra compras e saques',
  },
  {
    icon: 'easy-turning',
    title: 'Capital de giro',
    subtitle: 'Empréstimo facilitado ',
    span: '(sujeito a aprovação)',
  },
  {
    icon: 'coin',
    title: 'Reserva Stone',
    subtitle: 'Faça seu dinheiro render até 100% do CDI',
  },
];
