'use client';

import React, { useContext, useEffect } from 'react';

import { Choose, If } from '@/components/UtilitiesComponents';
import CreditVoucherCards from '@/components/v3/CreditVoucherCards';
import Testimonials from '@/components/v4/Testimonials';
import WhatsappButtonTestAB from '@/components/Widget/components/WhatsappButtonTestAB';
import { AmplitudeContext } from '@/contexts/amplitude';
import { HomeProps } from '@/pages';
import { showContaPJ } from '@/pages/conta-pj';

import BannerAccountPJ from './components/BannerAccountPJ';
import BeStone from './components/BeStone';
import ContactUs from './components/ContactUs';
import FirstInAttendance from './components/FirstInAttendance';
import JustAcquire from './components/JustAcquire';
import Partnerships from './components/Partnerships';
import Pillars from './components/Pillars';
import Tabs from './components/Tabs';

import bannersTempoMock from './bannersTempMock.json';
import { BannersCarousel } from '@/app/components/sections/BannersCarousel';
import { IPlan } from '../Plans/components/Plans/planType';

const Home: React.FC<HomeProps> = ({
  // banner,
  bannerAccountPJ,
  features,
  partnerships,
  justAcquire,
  creditVoucherCard,
  cardTabs,
  indicators,
  testimonials,
  featuresFooter,
  beStone,
}) => {
  const { analytics } = useContext(AmplitudeContext);

  useEffect(() => {
    analytics?.trackPageViewed();
  }, [analytics]);

  return (
    <div className="flex flex-col justify-center w-full">
      <WhatsappButtonTestAB />

      <BannersCarousel {...bannersTempoMock} plans={[] as IPlan[]} />

      {bannerAccountPJ && showContaPJ && (
        <BannerAccountPJ {...bannerAccountPJ} />
      )}
      {features && <Pillars {...features} />}
      {justAcquire && (
        <JustAcquire
          id={justAcquire.referId}
          tag={justAcquire.tag}
          title={justAcquire.title}
          description={justAcquire.description}
          image={justAcquire.image}
          buttons={justAcquire.buttons}
        />
      )}
      {indicators && (
        <FirstInAttendance
          id={indicators.referId}
          tag={indicators.tag}
          title={indicators.title}
          description={indicators.description}
          image={indicators.image}
          buttons={indicators.buttons}
          indicators={indicators.indicators}
          legend={indicators.legend}
        />
      )}

      {cardTabs && (
        <Tabs
          id={cardTabs.referId}
          title={cardTabs.title}
          subtitle={String(cardTabs.subTitle)}
          pages={cardTabs.cards.map(props => ({
            ...props,
            images: {
              mobile: {
                src: props.images.mobile.path,
                width: 380,
                height: 240,
                quality: 70,
                alt: props.images.mobile.alternativeText,
              },
              tablet: {
                src: props.images.tablet.path,
                width: 768,
                height: 374,
                quality: 60,
                alt: props.images.tablet.alternativeText,
              },
              web: {
                src: props.images.web.path,
                width: 1080,
                height: 430,
                quality: 60,
                alt: props.images.web.alternativeText,
              },
            },
          }))}
          containerClassName="md:my-40 lg:my-80"
        />
      )}
      {partnerships && (
        <Partnerships
          id={partnerships.referId}
          title={partnerships.title}
          partners={partnerships.partners}
        />
      )}
      {creditVoucherCard && (
        <CreditVoucherCards bg={0} {...creditVoucherCard} />
      )}
      {testimonials && <Testimonials {...testimonials} />}
      {beStone && (
        <BeStone
          id={beStone.referId}
          title={beStone.title}
          buttons={beStone.buttons}
        />
      )}
      {featuresFooter && (
        <ContactUs id={featuresFooter.referId} cards={featuresFooter.cards} />
      )}
    </div>
  );
};

export default Home;
