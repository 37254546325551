'use client';

import Image from 'next/image';
import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import ButtonBox from '@/components/ButtonBox';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { BannerProps } from '@/types/cms';
import FixedButton from './components/FixedButton';
interface Banner extends BannerProps {
  qrCodeLink?: string;
}
const Hero: React.FC<Banner> = ({
  referId,
  titleDescriptionTag: { title, description },
  buttons,
  bannerImages,
  qrCodeLink = LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ,
}) => {
  const { analytics } = useContext(AmplitudeContext);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina ',
          section_reference:
            'Tempo é dinheiro e com a conta stone você tem os dois',
        },
      });
    }
  }, [analytics, inView]);
  return (
    <section
      id={referId}
      ref={ref}
      className="flex justify-center p-24 md:py-40 lg:py-80 lg:px-24 w-full bg-display-0"
    >
      <FixedButton />
      <div className="flex flex-col md:flex-row gap-24 lg:gap-40 justify-center md:justify-between lg:items-center w-full max-w-screen-xl">
        <div className="flex flex-col w-full lg:max-w-[620px]">
          <h1
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className="flex flex-col justify-center md:justify-start items-center md:items-start w-full font-display text-5xl lg:text-7xl font-black leading-[44px] lg:leading-10 text-center md:text-left"
          />
          <p className="mt-8 w-full md:max-w-[300px] lg:max-w-[600px] font-normal text-lg lg:text-xl leading-[26px] lg:leading-5 text-center md:text-left">
            {description}
          </p>
          <div className="mt-24 lg:w-full md:w-fit">
            <ButtonBox
              qrCodeLink={qrCodeLink}
              buttons={buttons}
              section="Tempo é dinheiro e com a conta stone você tem os dois"
            />
          </div>
        </div>
        <div className="flex relative justify-center items-center w-full md:max-w-[348px] lg:max-w-[620px]">
          <div className="flex md:hidden overflow-hidden rounded-[16px]">
            <Image
              alt={bannerImages.mobile.alternativeText}
              src={bannerImages.mobile.path}
              width={314}
              height={233}
            />
          </div>
          <div className="hidden md:flex lg:hidden overflow-hidden max-h-[228px]">
            <Image
              alt={bannerImages.tablet.alternativeText}
              src={bannerImages.tablet.path}
              width={354}
              height={228}
            />
          </div>
          <div className="hidden lg:flex overflow-hidden">
            <Image
              alt={bannerImages.web.alternativeText}
              src={bannerImages.web.path}
              width={620}
              height={400}
              priority
              quality={80}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Hero;
