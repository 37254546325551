import Link from 'next/link';
import React from 'react';

import Icon from '@/components/v3/Icon';
import { MarkdownContent } from '@/components/v4/MarkdownContent';
import { FeaturesProps } from '@/types/cms';

const Pillars: React.FC<Omit<FeaturesProps, 'id'>> = ({
  referId,
  title,
  cards,
}) => {
  return (
    <div
      id={referId}
      className="flex flex-col gap-24 lg:gap-40 justify-center items-center py-40 lg:py-80 px-24 2xl:px-0"
    >
      {title && (
        <MarkdownContent
          content={title}
          className="font-display font-bold leading-[38px] text-center"
        />
      )}
      <div className="flex md:grid lg:flex flex-col lg:flex-row md:grid-cols-2 gap-16 justify-center lg:justify-between items-center lg:items-stretch w-full max-w-7xl">
        {cards.map(({ icon, description, title, link }, index) => (
          <div className="flex lg:flex-col p-16 lg:p-24 w-full rounded-md lg:rounded-2xl border-2 border-display-200 hover:border-stone-200 cursor-pointer">
            <Link
              key={index}
              href={{
                pathname: link ? link.href : '',
                hash: link ? link.hash : '',
              }}
              aria-label={`Link para a sessão ${title}`}
            >
              <div className="ml-16 min-w-max">
                <Icon name={icon.name} className="w-32 h-32" />
              </div>
              <div className="lg:pt-16 pl-16 lg:pl-0">
                <p className="font-display text-lg lg:text-xl font-medium text-stone-600">
                  {title}
                </p>
                <p className="pt-8 text-base lg:text-lg text-display-900">
                  {description}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pillars;
