import Icon from '@/components/v3/Icon';

import Flag from './components/Flag';
import { addOnMachine, FlagsItems } from './resources';

type TCreditVoucherCards = {
  bg?: 0 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  isFeature?: boolean;
};

const CreditVoucherCards: React.FC<TCreditVoucherCards> = ({
  bg = 0,
  isFeature,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center ${`bg-display-${bg}`}`}
    >
      <div className="flex flex-col justify-center items-center py-40 px-16 md:px-24 w-full md:max-w-[1195px]">
        <h2 className="font-display text-2xl md:text-xl lg:text-2xl font-bold leading-6 lg:leading-6 text-center">
          Aceite as principais formas de pagamento e{' '}
          <span className="text-stone-500">mais de 50 bandeiras</span>
        </h2>
        <div className="flex gap-8 mt-24 w-[310px] sm:w-[630px] md:w-[734px] lg:w-full">
          <Flag
            Flags={FlagsItems.others}
            title="Crédito, débito e outros"
            textBg={bg}
          />
        </div>
        <div className="flex flex-col gap-8 lg:gap-40 justify-center w-[310px] sm:w-[630px] md:w-[681px] lg:w-full">
          <Flag Flags={FlagsItems.vouchers} title="Vouchers" textBg={bg} />
        </div>
        {isFeature && (
          <div className="flex justify-center mt-24 w-full">
            <div className="flex justify-center w-full max-w-[1280px]">
              <div className="flex flex-wrap gap-12 lg:gap-24 justify-center w-full">
                {addOnMachine.map(({ name, iconName }) => (
                  <div className="flex justify-center items-center">
                    <Icon
                      name={iconName}
                      className="max-w-[24px] h-24 text-stone-500 fill-current"
                    />
                    <p className="ml-4 lg:ml-8 font-medium whitespace-nowrap paragraph-14 lg:paragraph-16">
                      {name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditVoucherCards;
