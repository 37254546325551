'use client';

import { GetStaticProps } from 'next';
/* eslint-disable react-hooks/exhaustive-deps */
import { CorporateContactJsonLd, NextSeo, SocialProfileJsonLd } from 'next-seo';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { widgetActiveChatIsOpenState } from '@/components/Widget/atoms';
import Home from '@/containers/Home';
import Layout from '@/providers/Layout';
import { stoneCMSGetBy } from '@/services/stoneCMSApi';
import {
  BannerProps,
  CardTabsProps,
  CreditVoucherCardProps,
  FeaturesProps,
  TestimonialsProps,
} from '@/types/cms';
import {
  BeStoneProps,
  FeaturesLinkProps,
  IndicatorsProps,
  JustAcquireProps,
  PartnershipsProps,
} from '@/types/cms/home';
import DatadogInit from '@/utils/datadog-init';

import MasterchefScrollingBar from '@/containers/Masterchef/components/MasterchefScrollingBar';
import { Choose } from '@/components/UtilitiesComponents';
import ScrollingBarBlackFriday from '@/components/ScrollingBarBlackFriday';

export interface HomeProps {
  banner: BannerProps;
  bannerAccountPJ: BannerProps;
  features: FeaturesProps;
  partnerships: PartnershipsProps;
  creditVoucherCard: CreditVoucherCardProps;
  cardTabs: CardTabsProps;
  testimonials: TestimonialsProps;
  justAcquire: JustAcquireProps;
  indicators: IndicatorsProps;
  featuresFooter: FeaturesLinkProps;
  beStone: BeStoneProps;
}

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const props = await stoneCMSGetBy('home');

  return {
    props,
  };
};

const Index: React.FC<HomeProps> = props => {
  const [, setWidgetActiveChatIsOpenState] = useRecoilState(
    widgetActiveChatIsOpenState,
  );
  useEffect(() => {
    setTimeout(() => setWidgetActiveChatIsOpenState(true), 5000);
  }, []);

  const isBlackFriday = process.env.IS_BLACK_FRIDAY === 'true';

  return (
    <>
      <DatadogInit />
      <NextSeo
        title="Stone: solução completa de vendas feita para o seu negócio"
        description="Conta PJ com cartão grátis, Maquininha com taxas personalizadas, Seguros e atendimento humano em 5 segundos. Bota pra girar com a Stone."
        canonical="https://www.stone.com.br/"
        openGraph={{
          type: 'website',
          url: 'https://www.stone.com.br/',
          title: 'Stone: solução completa de vendas feita para o seu negócio',
          description:
            'Conta PJ com cartão grátis, Maquininha com taxas personalizadas, Seguros e atendimento humano em 5 segundos. Bota pra girar com a Stone.',
          images: [
            {
              url: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1666733343/site-stone/structured-data/home/home-img-400x400.png',
              width: 400,
              height: 400,
              alt: 'Solução completa de vendas feita pro seu negócio | Stone',
              type: 'image/png',
            },
            {
              url: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1666733343/site-stone/structured-data/home/home-img-400x246.png',
              width: 400,
              height: 246,
              alt: 'Solução completa de vendas feita pro seu negócio | Stone',
              type: 'image/png',
            },
            {
              url: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1666733344/site-stone/structured-data/home/home-img-400x300.png',
              width: 400,
              height: 300,
              alt: 'Solução completa de vendas feita pro seu negócio | Stone',
              type: 'image/png',
            },
          ],
          site_name: 'Stone',
          locale: 'pt_BR',
        }}
      />
      <CorporateContactJsonLd
        url="http://www.stone.com.br/"
        logo="https://www.stone.com.br/public/images/favicon.ico"
        contactPoint={[
          {
            telephone: '3004-9680',
            contactType: 'customer service',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '3004-9681',
            contactType: 'sales',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '0800 326-0506',
            contactType: 'customer service',
            areaServed: 'BR',
            contactOption: 'TollFree',
            availableLanguage: ['Brazilian Portuguese'],
          },
        ]}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Stone Pagamentos"
        url="http://www.stone.com.br/"
        sameAs={[
          'https://www.facebook.com/stonepagamentos/',
          'https://twitter.com/sejastone',
          'https://www.instagram.com/stone/',
          'https://www.youtube.com/channel/UCk4L4JykgoDkN8YeuxkNqIQ',
          'https://www.linkedin.com/company/stone-pagamentos',
          'https://pt.wikipedia.org/wiki/Stone_Pagamentos',
        ]}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `#widget-open-floating-action-button{display:none}`,
        }}
      />

      <Choose>
        <Choose.When condition={isBlackFriday}>
          <ScrollingBarBlackFriday />
        </Choose.When>
        <Choose.Otherwise>
          <MasterchefScrollingBar hash="formularioMaquinaDeCartao" />
        </Choose.Otherwise>
      </Choose>

      <Layout layoutName="default">
        <Home {...props} />
      </Layout>
    </>
  );
};

export default Index;
