/* eslint-disable tailwindcss/no-custom-classname */

'use client';

import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';
import React, { useState } from 'react';

import Icon from '@/components/v3/Icon';
import { TestimonialsProps } from '@/types/cms';

import { MarkdownContent } from '../MarkdownContent';
import HangLoose from './SVGs/hangLoose.svg';

const Testimonials: React.FC<TestimonialsProps> = ({
  referId,
  title,
  testimonialsCard,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 2,
      spacing: 40,
    },
    initial: 0,
    mode: 'snap',
    loop: false,
    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          perView: 1.18,
          spacing: 24,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
      '(max-width: 640px)': {
        slides: {
          perView: 1.18,
          spacing: 16,
        },
      },
    },
    slideChanged(s) {
      setCurrentSlide(Math.round(s.track.details.rel));
    },
  });

  return (
    <div className="p-16 py-40 lg:py-80 md:pr-0 md:pl-24" id={referId}>
      <div className="flex flex-col md:flex-row gap-16 md:gap-24 lg:gap-40 items-center mx-auto md:max-w-[1060px] bg-display-0">
        <div className="flex flex-col gap-16 md:gap-24 justify-center w-full md:min-w-[290px] md:max-w-[290px]">
          <div className="px-16 md:px-0 ">
            <HangLoose />
            <h2 className="mt-16 md:max-w-[286px] font-display text-2xl lg:text-3xl font-bold leading-6 lg:leading-7">
              {title}
            </h2>
          </div>
          {slider && (
            <div className="hidden md:flex gap-12 ml-auto">
              <button
                type="button"
                className="cursor-pointer"
                title="botão anterior"
                onClick={() => {
                  slider.current?.prev();
                }}
                disabled={currentSlide === 0}
              >
                <Icon
                  name="arrow-left-2x"
                  className={classNames(
                    `h-40 w-40 fill-current text-stone-500`,
                    {
                      'text-display-500': currentSlide === 0,
                    },
                  )}
                />
                <p className="hidden">arrow-left</p>
              </button>
              <button
                type="button"
                className="cursor-pointer"
                title="botão avançar"
                onClick={() => {
                  slider.current?.next();
                }}
                disabled={currentSlide === testimonialsCard.length - 1}
              >
                <Icon
                  name="arrow-right-2x"
                  className={classNames(
                    `h-40 w-40 fill-current text-stone-500`,
                    {
                      'text-display-500':
                        currentSlide === testimonialsCard.length - 2,
                    },
                  )}
                />
                <p className="hidden">arrow-right</p>
              </button>
            </div>
          )}
        </div>
        <div
          ref={sliderRef}
          id="my-keen-slider"
          className="flex overflow-hidden m-2 max-w-full keen-slider"
        >
          {testimonialsCard.map(({ image, name, profile, testimonial }) => (
            <div
              className="flex flex-col gap-8 lg:gap-12 p-16 w-[345px] bg-display-100 rounded-md keen-slider__slide"
              key={name}
            >
              <div className="flex">
                <div className="overflow-hidden w-48 min-w-[48px] h-48 rounded">
                  <Image
                    src={image.path}
                    alt={image.alternativeText}
                    width={48}
                    height={48}
                    quality={80}
                    style={{ objectFit: 'cover' }}
                  />
                </div>
                <div className="ml-12">
                  <p className="font-bold paragraph-14">{name}</p>
                  <p className="mt-4 text-display-500 paragraph-14">
                    {profile}
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <MarkdownContent
                  content={testimonial}
                  className="mt-auto paragraph-16"
                />
              </div>
            </div>
          ))}
        </div>
        {slider && (
          <div className="flex md:hidden gap-12 justify-center">
            <button
              type="button"
              className="cursor-pointer"
              title="botão anterior"
              onClick={() => {
                slider.current?.prev();
              }}
              disabled={currentSlide === 0}
            >
              <Icon
                name="arrow-left-2x"
                className={classNames(`h-40 w-40 fill-current text-stone-500`, {
                  'text-display-500': currentSlide === 0,
                })}
              />
              <p className="hidden">arrow-left-2x</p>
            </button>
            <button
              type="button"
              className="cursor-pointer"
              title="botão avançar"
              onClick={() => {
                slider.current?.next();
              }}
              disabled={currentSlide === testimonialsCard.length - 1}
            >
              <Icon
                name="arrow-right-2x"
                className={classNames(`h-40 w-40 fill-current text-stone-500`, {
                  'text-display-500':
                    currentSlide === testimonialsCard.length - 1,
                })}
              />
              <p className="hidden">arrow-right-2x</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
