/* eslint-disable tailwindcss/no-custom-classname */

'use client';

import 'keen-slider/keen-slider.min.css';

import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider-legacy/react';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import { useState } from 'react';

import ButtonBox from '@/components/ButtonBox';
import Icon from '@/components/v3/Icon';
import { tabScrollToCenter } from '@/services/tabScrollToCenter';
import { ButtonProps } from '@/types/cms';

import styles from './Tabs.module.css';

interface pagesProps {
  tag: string;
  title: string;
  description: string;
  label: string;
  buttons: ButtonProps[];
  images: {
    mobile: ImageProps;
    tablet: ImageProps;
    web: ImageProps;
  };
}

export interface TabCardsProps {
  id: string;
  title: string;
  subtitle: string;
  pages: pagesProps[];
}

const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `https://res.cloudinary.com/dunz5zfpt/f_auto,w_${width},q_${
    quality || 70
  }/${src}`;
};

type TTabsClassNames = {
  containerClassName?: string;
};
const Tabs: React.FC<TabCardsProps & TTabsClassNames> = ({
  id,
  title,
  subtitle,
  pages,
  containerClassName,
}) => {
  const [currentOpacities, setCurrentOpacities] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: pages.length,
    initial: 0,
    centered: true,
    loop: false,
    controls: false,
    duration: 0,
    vertical: false,
    spacing: 40,
    move(s) {
      const newOpacities = s.details().positions.map(slide => slide.portion);
      setCurrentSlide(s.details().relativeSlide);
      setCurrentOpacities(newOpacities);
      tabScrollToCenter(
        s.details().relativeSlide,
        '#tabsContainerId',
        '.tabs-wrapper',
      );
    },
  });

  const handleTabs = (
    <div className="flex w-full md:bg-transparent">
      <div id={id} className={styles.tabContainer}>
        {pages.map(({ label }, index) => (
          <div
            key={label}
            className={`tabs-wrapper ${styles.tabWrapper}`}
            data-tab-index={index}
          >
            <div
              className={
                styles.tabButtonWrapper && currentSlide === index
                  ? styles.tabButtonActive
                  : styles.tabButton
              }
            >
              <button
                key={label}
                type="button"
                data-index={label}
                aria-label={label}
                onClick={() => {
                  instanceRef.moveToSlide(index);
                }}
                id={`fazer-negocio-girar-${index}`}
              >
                <h3>{label}</h3>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div
      id="totalmente-integrado"
      className={`flex flex-col justify-center items-center my-40 md:my-80 lg:mx-24 xl:mx-0 ${containerClassName}`}
    >
      <h2 className="mx-24 md:mx-auto lg:mx-24 mb-16 md:mb-8 max-w-[455px] md:max-w-none font-display md:text-3xl lg:text-4xl text-center heading-3">
        {title}
      </h2>
      {subtitle && (
        <p className="mb-16 md:mb-24 text-lg lg:text-xl text-center text-display-900">
          {subtitle}
        </p>
      )}
      <div className="flex md:hidden flex-col">
        <div className="flex overflow-hidden mt-16 w-full max-w-[455px] h-full min-h-[240px] rounded-t-2xl">
          <Image
            {...pages[currentSlide].images.mobile}
            style={{ objectFit: 'cover' }}
            loader={imageLoader}
            alt={pages[currentSlide].images.mobile.alt}
          />
        </div>
      </div>
      <div className="flex justify-center w-full">{handleTabs}</div>
      <div
        ref={sliderRef}
        className="md:overflow-hidden relative mt-24 md:mt-0 w-full max-w-full md:max-w-[1060px] h-[280px] md:h-[344px] lg:h-[422px] fader"
      >
        {pages.map(
          ({ images, description, title, buttons, tag, label }, index) => (
            <div
              key={`card - ${title}`}
              className={classNames(
                `fader__slide absolute w-full h-full flex items-stretch md:px-24 lg:px-0`,
                {
                  [`number-slide-${index + 1}`]: index,
                },
              )}
              style={{ opacity: currentOpacities[index] }}
            >
              <div className="flex overflow-hidden md:relative flex-col md:flex-row items-center md:p-24 lg:px-40 w-full md:rounded-2xl lg:rounded-6xl">
                <div className="hidden md:flex lg:hidden md:absolute right-0 bottom-0 z-0">
                  <Image
                    style={{ objectFit: 'cover' }}
                    loader={imageLoader}
                    {...images.tablet}
                    alt={images.tablet.alt}
                  />
                </div>
                <div className="hidden lg:flex md:absolute inset-0 z-0">
                  <Image
                    {...images.web}
                    style={{ objectFit: 'cover' }}
                    loader={imageLoader}
                    alt={images.web.alt}
                  />
                </div>
                <div
                  key={`${title}-${description}`}
                  className="z-10 md:p-[23px] lg:p-40 px-24 md:mt-0 w-full max-w-[455px] md:max-w-[362px] lg:max-w-[455px] text-left md:bg-display-0 md:rounded-md lg:rounded-2xl"
                >
                  <p className="block mb-16 font-bold text-display-600 uppercase paragraph-14 lg:paragraph-16">
                    {tag}
                  </p>
                  <h4 className="mb-8 font-display text-2xl md:leading-[32px] text-display-900 heading-4 lg:heading-4">
                    {title}
                  </h4>
                  <p className="mb-16 text-display-900 paragraph-16 lg:paragraph-18">
                    {description}
                  </p>
                  <div className="w-full md:w-max">
                    <ButtonBox
                      buttons={buttons}
                      cta={label}
                      section={`unico app que voce vai precisar | ${title}`}
                      className="flex order-2 md:order-3 w-full md:w-max font-semibold text-display-0 bg-stone-500 btn btn-primary btn-large"
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
      </div>

      <div className="flex md:hidden justify-center w-full bg-transparent">
        <button
          type="button"
          className="mr-6 w-40 h-40 cursor-pointer"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any) => e.stopPropagation() || instanceRef.prev()}
          disabled={currentSlide === 0}
          aria-label="Voltar para aba anterior"
        >
          <Icon
            name="arrow-left-2x"
            className={classNames(
              `h-40 w-40 fill-current text-stone-500 md:h-48 md:w-48`,
              {
                'text-display-400': currentSlide === 0,
              },
            )}
          />
        </button>
        <button
          type="button"
          className="ml-6 w-40 h-40 cursor-pointer"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any) => e.stopPropagation() || instanceRef.next()}
          disabled={currentSlide === pages.length - 1}
          aria-label="Ir para próxima aba"
        >
          <Icon
            name="arrow-right-2x"
            className={classNames(
              `h-40 w-40 fill-current text-stone-500 md:h-48 md:w-48`,
              {
                'text-display-400': currentSlide === pages.length - 1,
              },
            )}
          />
        </button>
      </div>
    </div>
  );
};

export default Tabs;
