import React from 'react';

type Props = {
  title: string;
  value: string;
  legend: string;
};

const Indicator: React.FC<Props> = ({ title, value, legend }) => {
  return (
    <div className="lg:p-8 py-16 px-8 space-y-4 md:space-y-12 min-w-[152px] text-center bg-display-100 rounded-[8px] md:rounded-md">
      <p className="font-normal text-sm md:text-base">{title}</p>
      <p className="font-display text-4xl md:text-6xl font-bold">{value}</p>
      <p className="font-normal text-sm md:text-base">{legend}</p>
    </div>
  );
};

export default Indicator;
