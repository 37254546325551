import Image from 'next/image';

import RA1000CertIcon from '@/assets/illustrations/ra1000/certificado-RA1000.svg';
import RA1000MiniIcon from '@/assets/illustrations/ra1000/selo-RA1000-mini.svg';
import RA1000Icon from '@/assets/illustrations/ra1000/selo-RA1000.svg';
import ButtonBox from '@/components/ButtonBox';
import { IndicatorsProps } from '@/types/cms/home';

import Indicator from './Indicator';

interface FirstInAttendanceProps extends Omit<IndicatorsProps, 'referId'> {
  id: string;
}
const FirstInAttendance: React.FC<FirstInAttendanceProps> = ({
  id,
  tag,
  title,
  description,
  image,
  indicators,
  buttons,
  legend,
}) => {
  return (
    <section id={id}>
      <div className="relative w-full h-[213px] md:h-[395px] lg:h-[466px]">
        <div className="hidden lg:flex">
          <Image
            src={image.web.path}
            alt={image.web.alternativeText}
            quality={55}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>

        <div className="hidden md:flex lg:hidden">
          <Image
            src={image.tablet.path}
            alt={image.tablet.alternativeText}
            quality={50}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>

        <div className="flex md:hidden">
          <Image
            src={image.mobile.path}
            alt={image.mobile.alternativeText}
            quality={80}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>
      </div>

      <div className="mx-24 mb-40 md:mb-0">
        <div className="grid relative md:grid-cols-1 lg:grid-cols-2 sm:p-24 md:p-40 m-auto mt-[24px] md:mt-[-134px] lg:mt-[-163px] lg:mb-[60px] max-w-[1060px] bg-display-0 md:rounded-md md:border-2 md:border-display-200">
          <div className="flex flex-col justify-center lg:max-w-[387px]">
            <p className="text-sm md:text-base font-semibold text-center lg:text-left text-display-600">
              {tag}
            </p>
            <h2 className="pt-8 font-display text-2xl md:text-3xl font-bold text-center lg:text-left text-display-900">
              {title}
            </h2>
            <p className="pt-8 font-normal text-base md:text-lg text-center lg:text-left text-display-900">
              {description}
            </p>
            <div className="hidden lg:flex pt-24">
              <ButtonBox
                buttons={buttons}
                section="Numero 1 em atendimento"
                className="m-auto lg:m-0 w-full md:w-auto font-semibold text-display-0 bg-stone-500 btn btn-primary btn-large"
                cta="Fale com a gente"
              />
            </div>
          </div>

          <div className="flex flex-col items-center md:items-end mt-24 lg:mt-0">
            <div className="flex flex-col items-center w-full">
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-8 md:gap-16 w-full">
                <div className="lg:p-8 py-16 px-8 min-w-[152px] text-center bg-display-100 rounded-[8px] md:rounded-md">
                  <p className="font-normal text-sm md:text-base">
                    Reconhecido
                  </p>
                  <div className="flex justify-center items-center">
                    <RA1000Icon />
                    <div className="flex flex-col justify-start items-start ml-[3px] font-display">
                      <RA1000CertIcon />
                      <p className="pt-4 font-display ">
                        <span className="text-4xl font-bold">9.0</span>
                        <span className="text-xl">/10</span>
                      </p>
                    </div>
                  </div>
                </div>
                {indicators.map(({ title, value, legend }) => (
                  <Indicator
                    key={title}
                    title={title}
                    value={value}
                    legend={legend}
                  />
                ))}
              </div>
              <div className="flex pt-16">
                <RA1000MiniIcon />{' '}
                <p
                  className="pl-[4px] font-display text-base md:text-xl font-medium"
                  style={{ color: '#018D45' }}
                >
                  {legend}
                </p>
              </div>
              <div className="flex lg:hidden mt-24 lg:mt-0 w-full">
                <ButtonBox
                  buttons={buttons}
                  section={title}
                  className="m-auto lg:m-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstInAttendance;
