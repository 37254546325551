import { useContext } from 'react';

import { AmplitudeContext } from '@/contexts/amplitude';

type WaitingFetchVariant = null;
type DisabledAmplitudeTest = undefined;
type ExperimentVariant = string;

type VariantValue =
  | WaitingFetchVariant
  | DisabledAmplitudeTest
  | ExperimentVariant;

type HookResponse = {
  activeVariant: VariantValue;
};

export const useAmplitudeTestAB = (
  experimentIdEnv?: string,
  active = true,
): HookResponse => {
  const context = useContext(AmplitudeContext);

  if (!context?.useAmplitudeTestAB)
    return {
      activeVariant: !active ? undefined : null,
    };

  if (!experimentIdEnv) {
    throw new Error('undefined experiment id environment variable');
  }

  return context.useAmplitudeTestAB(experimentIdEnv, active);
};

export default useAmplitudeTestAB;
