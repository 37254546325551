import classNames from 'classnames';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const OnDesktop: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames('hidden lg:flex', className)}>{children}</div>
  );
};

export default OnDesktop;
