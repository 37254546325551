'use client';

import { useContext, useEffect, useState } from 'react';

import ButtonBox from '@/components/ButtonBox';
import Faq from '@/components/Faq';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { PjAccountProps, showContaPJ } from '@/pages/conta-pj';
import Layout from '@/providers/Layout';

import BlogPosts from './components/BlogPosts';
import ComparativeList from './components/ComparativeList';
import ComparativeListOld from './components/ComparativeList/ComparativeList';
import Features from './components/Features';
import FeaturesNewOffer from './components/FeaturesNewOffer';
import { resource } from './components/FeaturesNewOffer/features';
import FixedTabs from './components/FixedTabs';
import HeroNewOffer from './components/HeroNewOffer';
import MeetSolutions from './components/MeetSolutions';
import Hero from './components/Hero';
import RTB from './components/RTB';
import SectionRotate from './components/SectionRotate/SectionRotate';
import SellMultipleWays from './components/SellMultipleWays';
import ServiceVideoPj from './components/ServiceVideo';
import HowWorks from './components/StepByStep';
import Testimonials from './components/Testimonials';

const EnterpriseAccount: React.FC<PjAccountProps> = ({
  hero,
  serviceVideoPj,
  features,
  bannerCard,
  videoSteps,
  sectionGirar,
  comparativeList,
  rtbFeatures,
  testimonials,
  solutionsFeatures,
  blogPosts,
  faq,
}) => {
  const { analytics } = useContext(AmplitudeContext);
  useEffect(() => {
    analytics?.trackPageViewed();
  }, [analytics]);

  const [stepNumber, setStepNumber] = useState(0);

  return (
    <>
      <Layout>
        {showContaPJ ? <HeroNewOffer /> : hero && <Hero {...hero} />}
        <FeaturesNewOffer features={resource} />
        {serviceVideoPj && (
          <ServiceVideoPj
            {...serviceVideoPj}
            analyticsReference="Vender de diversas formas, gerir em perder tempo, e girar com o dinheiro certo na hora certa"
          />
        )}

        {features.cards && (
          <FixedTabs {...features} HeightSectionPrimary={stepNumber}>
            {features && <Features {...features} />}
            {bannerCard && (
              <SellMultipleWays
                {...bannerCard}
                setStepNumber={setStepNumber}
                analyticsReference="Venda de multiplas formas com sua conta PJ"
              />
            )}
            {videoSteps && <HowWorks {...videoSteps} />}
            {sectionGirar && (
              <SectionRotate
                {...sectionGirar}
                analyticsReference="E pro seu negócio girar temos tudo que voce precisa"
              />
            )}
          </FixedTabs>
        )}
        {comparativeList && showContaPJ ? (
          <ComparativeList {...comparativeList} />
        ) : (
          <ComparativeListOld />
        )}

        {rtbFeatures && <RTB {...rtbFeatures} />}
        {testimonials && <Testimonials {...testimonials} />}
        {solutionsFeatures && <MeetSolutions {...solutionsFeatures} />}
        {blogPosts && <BlogPosts {...blogPosts} />}
        {faq && (
          <Faq {...faq} btnToTop buttonIsShowMobileAndTablet={false}>
            <ButtonBox
              buttons={faq.buttons}
              section={faq.title}
              qrCodeLink={LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ}
            />
          </Faq>
        )}
      </Layout>
    </>
  );
};

export default EnterpriseAccount;
