import Image from 'next/image';

import ButtonBox from '@/components/ButtonBox';
import Icon from '@/components/v3/Icon';
import { MarkdownContent } from '@/components/v4/MarkdownContent';
import { BannerProps } from '@/types/cms';

type IBannerAccountPJ = Omit<BannerProps, 'id' | 'machineImage'>;

const BannerAccountPJ: React.FC<IBannerAccountPJ> = ({
  referId,
  titleDescriptionTag,
  iconsDescriptions,
  bannerImages,
  buttons,
}) => {
  return (
    <div
      id={referId}
      className="flex flex-col-reverse md:flex-row gap-24 lg:gap-80 justify-center items-center py-40 lg:py-80 px-24 bg-[#00170E] rounded-br-6xl lg:rounded-br-10xl"
    >
      <div className="flex flex-col justify-center items-center md:items-start w-full lg:max-w-[527px]">
        <MarkdownContent content={titleDescriptionTag?.title} />

        <p className="font-normal text-lg leading-[26px] text-center md:text-left text-display-0">
          {titleDescriptionTag?.description}
        </p>
        <div className="flex flex-col gap-24 mt-24">
          <div className="flex flex-col gap-16">
            {iconsDescriptions?.map(({ icon, description }) => (
              <div className="flex gap-8">
                <Icon
                  name={icon.name}
                  className="min-w-[24px] min-h-[24px] text-stone-500 fill-current"
                />
                <p className="text-display-0">{description}</p>
              </div>
            ))}
          </div>
          <div className="w-full md:w-max lg:w-max">
            <ButtonBox buttons={buttons} section={titleDescriptionTag?.title} />
          </div>
        </div>
      </div>
      <div className="flex relative justify-center items-center w-full md:max-w-[348px] lg:max-w-[620px]">
        <div className="flex md:hidden overflow-hidden rounded-[16px]">
          <Image
            alt={bannerImages.mobile.alternativeText}
            src={bannerImages.mobile.path}
            width={312}
            height={225}
            quality={80}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="hidden md:flex lg:hidden overflow-hidden max-h-[531px]">
          <Image
            alt={bannerImages.tablet.alternativeText}
            src={bannerImages.tablet.path}
            width={348}
            height={250}
            quality={80}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="hidden lg:flex">
          <Image
            alt={bannerImages.web.alternativeText}
            src={bannerImages.web.path}
            width={650}
            height={539}
            quality={80}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerAccountPJ;
