'use client';

import { useContext } from 'react';
import Link from 'next/link';
import { AmplitudeContext } from '@/contexts/amplitude';
import ScrollingBar, { ScrollingBarProps } from './ScrollingBar';

interface ScrollingBarWithLinkProps extends ScrollingBarProps {
  ctaReference?: string;
  eventDescription?: string;
  hash?: string;
  pathname?: string;
  eventType?: string;
  sectionReference?: string;
}

const ScrollingBarWithLink: React.FC<ScrollingBarWithLinkProps> = ({
  hash,
  ctaReference,
  pathname,
  children,
  className,
  eventDescription,
  phraseRepeats,
  eventType = 'cta stone',
  sectionReference = 'CTA Reference',
}) => {
  const { analytics } = useContext(AmplitudeContext);

  const handleClick = () => {
    analytics?.track({
      event_type: eventType,
      event_properties: {
        name: eventType,
        description: eventDescription,
        section_reference: sectionReference,
        cta_reference: ctaReference,
      },
    });
  };

  return (
    <Link href={{ pathname, hash }} prefetch={false}>
      <div onClick={() => handleClick()}>
        <ScrollingBar className={className} phraseRepeats={phraseRepeats}>
          {children}
        </ScrollingBar>
      </div>
    </Link>
  );
};

export default ScrollingBarWithLink;
