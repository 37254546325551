export const tabScrollToCenter = (
  slideIndexNumber: number,
  elementScroll: string,
  tabsWrapper: string,
) => {
  const tabComponentEl = document.querySelector(
    `${elementScroll}`,
  ) as HTMLElement;

  if (!tabComponentEl) {
    return;
  }

  // Eixo inicial base do scroll
  const initialScrollToAxis = tabComponentEl.offsetWidth / 2;

  const allTabsElement = tabComponentEl.querySelectorAll(
    `${tabsWrapper}`,
  ) as NodeListOf<HTMLElement>;

  const scrollToPosition = [...allTabsElement].reduce(
    (acc, currentEl, index) => {
      if (index <= slideIndexNumber) {
        return acc + currentEl.offsetWidth;
      }

      return acc + 0;
    },
    0,
  );

  const tabSizeCurrent = allTabsElement[slideIndexNumber].offsetWidth / 2;
  const scrollToPositionFinal =
    scrollToPosition - tabSizeCurrent - initialScrollToAxis;

  tabComponentEl.scrollTo(scrollToPositionFinal, 0);
};
