import classNames from 'classnames';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const OnTablet: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames('hidden md:flex lg:hidden', className)}>
      {children}
    </div>
  );
};

export default OnTablet;
